<template>
    <form @submit.prevent="submit()">
        <b-form-group :label="$t('crud.name')">
            <b-form-input v-model="record.name" required></b-form-input>
        </b-form-group>
      <b-form-group label="Special type">
        <b-form-select v-model="record.name_key">
          <template slot="first">
            <option :value="null">[ {{$t('crud.products.types.none')}} ]</option>
            <option value="fertilizer">{{$t('crud.products.types.fertilizer')}}</option>
            <option value="extra_fertilizer">{{$t('crud.products.types.extra_fertilizer')}}</option>
            <option value="delivery">{{$t('crud.orders.shipping_methods.delivery')}}</option>
            <option value="refund">{{$t('crud.products.types.refund')}}</option>
          </template>
        </b-form-select>
      </b-form-group>
        <b-form-group :label="$t('crud.products.unit_name')">
            <b-form-input v-model="record.unit_name"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.products.short_description')">
            <b-form-textarea v-model="record.short_description" rows="4"></b-form-textarea>
        </b-form-group>
        <b-form-group :label="$t('crud.products.description')">
            <b-form-textarea v-model="record.description" rows="4"></b-form-textarea>
        </b-form-group>
        <b-form-group :label="$t('crud.products.image')">
            <b-form-input v-model="record.image"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.products.image_alt')">
            <b-form-input v-model="record.image_alt"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.products.weight')">
            <b-form-input v-model="record.weight" @keypress.native="onlyNumber"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.products.price')" :description="$t('crud.products.price_description')">
            <b-form-input v-model="record.price" @keypress.native="onlyNumber" required></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.products.min_quantity')" :description="$t('crud.products.min_quantity_description')">
            <b-form-input v-model="record.min_quantity" required></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.products.max_quantity')" :description="$t('crud.products.max_quantity_description')">
            <b-form-input v-model="record.max_quantity"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.products.vat_percentage')">
            <b-form-input v-model="record.vat_percentage"></b-form-input>
        </b-form-group>
        <button class="btn btn-primary" type="submit">{{ $t('crud.save') }}</button>
    </form>
</template>

<script>
    export default {
        name: 'products-form-component',
        props: ['record'],
        methods: {
            submit() {
                this.$emit('submit');
            }
        }
    }
</script>
